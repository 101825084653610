// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LineChartOutlined, DashboardOutlined, GoldOutlined } from '@ant-design/icons';

const icons = {
  dashboard: DashboardOutlined,
  components: GoldOutlined,
  LineChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const pricingSolution = {
  id: 'kpi',
  title: <FormattedMessage id="kpis" />,
  type: 'group',
  children: [
    {
      id: 'ltl-insights',
      title: <FormattedMessage id="ltl-insights" />,
      type: 'item',
      url: '/ltl-insights',
      icon: icons.LineChartOutlined
    },
    {
      id: 'courier-insights',
      title: <FormattedMessage id="courier-insights" />,
      type: 'item',
      url: '/courier-insights',
      icon: icons.LineChartOutlined
    },
    {
      id: 'forecast-tracker',
      title: <FormattedMessage id="forecast-tracker" />,
      type: 'item',
      url: '/forecast-tracker',
      icon: icons.LineChartOutlined
    }
  ]
};

export default pricingSolution;
