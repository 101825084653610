// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BuildOutlined,
  BarChartOutlined,
  FormOutlined,
  LineChartOutlined,
  DeploymentUnitOutlined,
  DashboardOutlined,
  GoldOutlined
} from '@ant-design/icons';

const icons = {
  dashboard: DashboardOutlined,
  components: GoldOutlined,
  LineChartOutlined,
  DeploymentUnitOutlined,
  FormOutlined,
  BarChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const pricingSolution = {
  id: 'pricing-solution',
  title: <FormattedMessage id="pricing-solution" />,
  type: 'group',
  children: [
    {
      id: 'rate-increases',
      title: <FormattedMessage id="rate-increases" />,
      type: 'item',
      url: '/rate-increases',
      icon: icons.LineChartOutlined
    },
    {
      id: 'network-optimization',
      title: <FormattedMessage id="network-optimization" />,
      type: 'item',
      url: '/projects',
      icon: BuildOutlined
    }
  ]
};

export default pricingSolution;
