import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));
const Dashboard = Loadable(lazy(() => import('pages/dashboard/index')));
const RateIncreases = Loadable(lazy(() => import('pages/pricing-solution/rate-increase/rate-increases')));
const NetworkOptimization = Loadable(lazy(() => import('pages/pricing-solution/network-optimization')));
const HeaderMapping = Loadable(lazy(() => import('pages/pricing-solution/header-mapping')));
const NewProjectQuestionnaire = Loadable(lazy(() => import('pages/pricing-solution/new-project-questionnaire')));
const Projects = Loadable(lazy(() => import('pages/pricing-solution/network-optimization/projects')));
const RateProjectDetails = Loadable(lazy(() => import('pages/pricing-solution/rate-increase/rate-project-details')));
const RateScenarioDetails = Loadable(lazy(() => import('pages/pricing-solution/rate-increase/rate-scenario-details')));
const ProjectDetails = Loadable(lazy(() => import('pages/pricing-solution/network-optimization/project-details')));
const ScenarioSummary = Loadable(lazy(() => import('pages/pricing-solution/scenario-summary')));
const ScenarioDetails = Loadable(lazy(() => import('pages/pricing-solution/network-optimization/scenario-details')));
const ScenarioCreate = Loadable(lazy(() => import('pages/pricing-solution/create-scenario')));
const LTLInsights = Loadable(lazy(() => import('pages/kpi/ltl-insights')));
const CourierInsights = Loadable(lazy(() => import('pages/kpi/courier-insights')));
const ForecastTracker = Loadable(lazy(() => import('pages/kpi/forecast-tracker')));
const Carrier = Loadable(lazy(() => import('pages/config/carrier')));
const CarrierDetail = Loadable(lazy(() => import('pages/config/carrierDetail')));
const Rate = Loadable(lazy(() => import('pages/config/rate')));
const Zone = Loadable(lazy(() => import('pages/config/zone')));
const Terminal = Loadable(lazy(() => import('pages/config/terminal')));
const ViewRate = Loadable(lazy(() => import('pages/config/viewRate')));
const ViewZone = Loadable(lazy(() => import('pages/config/viewZone')));
const CarrierServiceDetail = Loadable(lazy(() => import('pages/config/carrierServiceDetail')));
const DataImporterMain = Loadable(lazy(() => import('pages/data-importer/data-importer-new')));
const Master = Loadable(lazy(() => import('pages/data-importer/master')));
const Substitute = Loadable(lazy(() => import('pages/config/substitute')));
const Ingore = Loadable(lazy(() => import('pages/config/ingore')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'substitute',
          element: <Substitute />
        },
        {
          path: 'ingore',
          element: <Ingore />
        },
        {
          path: 'data-importer',
          element: <Master />
        },
        {
          path: 'rate-increases',
          element: <RateIncreases />
        },
        {
          path: 'network-optimization',
          element: <NetworkOptimization />
        },
        {
          path: 'header-mapping',
          element: <HeaderMapping />
        },
        {
          path: 'new-project-questionnaire',
          element: <NewProjectQuestionnaire />
        },
        {
          path: 'projects',
          element: <Projects />
        },
        {
          path: '/rate-increases/project-details/:id',
          element: <RateProjectDetails />
        },
        {
          path: '/rate-increases/:projectname/:project_type/scenario/:order_num/:scenario_id',
          element: <RateScenarioDetails />
        },
        {
          path: '/projects/network-optimization/project-details/:id',
          element: <ProjectDetails />
        },
        {
          path: 'scenario-summary',
          element: <ScenarioSummary />
        },
        {
          path: '/project/:projectname/scenario/:order_num/:scenario_id',
          element: <ScenarioDetails />
        },
        {
          path: '/create-scenario',
          element: <ScenarioCreate />
        },
        {
          path: 'ltl-insights',
          element: <LTLInsights />
        },
        {
          path: 'courier-insights',
          element: <CourierInsights />
        },
        {
          path: 'forecast-tracker',
          element: <ForecastTracker />
        },
        {
          path: 'carrier',
          element: <Carrier />
        },
        {
          path: 'carrier/carrier_detail/:id',
          element: <CarrierDetail />
        },
        {
          path: 'carrier/carrier_detail/:id/carrier_service_detail/:id',
          element: <CarrierServiceDetail />
        },
        {
          path: 'rate',
          element: <Rate />
        },
        {
          path: 'rate/rate_data/:id',
          element: <ViewRate />
        },
        {
          path: 'zone',
          element: <Zone />
        },
        {
          path: 'terminal',
          element: <Terminal />
        },
        {
          path: 'zone/zone_data/:id',
          element: <ViewZone />
        },
        {
          path: 'sample-page',
          element: <SamplePage />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
