// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DashboardOutlined, GoldOutlined, CloudUploadOutlined } from '@ant-design/icons';

const icons = {
  dashboard: CloudUploadOutlined,
  components: GoldOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const dataImporter = {
  id: 'first-section',
  title: <FormattedMessage id="Upload" />,
  type: 'group',
  children: [
    {
      id: 'data-importer',
      title: <FormattedMessage id="Data Importer" />,
      type: 'item',
      url: '/data-importer',
      icon: icons.dashboard
    }
  ]
};

export default dataImporter;
