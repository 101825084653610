// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DashboardOutlined, GoldOutlined } from '@ant-design/icons';

const icons = {
  dashboard: DashboardOutlined,
  components: GoldOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'first-section',
  title: <FormattedMessage id="overview" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.dashboard
    }
  ]
};

export default other;
