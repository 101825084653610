// project import

import dashboard from './dashboard';
import pricingSolution from './pricing-solution';
import kpis from './kpis';
import systemConfig from './system-config';
import dataImporter from './data-importer';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, dataImporter, pricingSolution, systemConfig]
  // items: [dashboard, pricingSolution, kpis, systemConfig]
};

export default menuItems;
