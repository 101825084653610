// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  CarOutlined,
  DollarOutlined,
  GlobalOutlined,
  SettingOutlined,
  CustomerServiceOutlined,
  ApiOutlined,
  SyncOutlined
} from '@ant-design/icons';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const systemConfig = {
  id: 'system-config',
  title: <FormattedMessage id="System Configuration" />,
  type: 'group',
  children: [
    {
      id: 'carrier-set-up',
      title: <FormattedMessage id="Config" />,
      type: 'collapse',
      icon: SettingOutlined,
      children: [
        {
          id: 'carrier',
          title: <FormattedMessage id="Carrier" />,
          type: 'item',
          url: '/carrier',
          icon: CarOutlined
        },
        {
          id: 'rate',
          title: <FormattedMessage id="Rate" />,
          type: 'item',
          url: '/rate',
          icon: DollarOutlined
        },
        {
          id: 'zone',
          title: <FormattedMessage id="Zone" />,
          type: 'item',
          url: '/zone',
          icon: GlobalOutlined
        },
        {
          id: 'terminal',
          title: <FormattedMessage id="Terminal" />,
          type: 'item',
          url: '/terminal',
          icon: GlobalOutlined
        },
        {
          id: 'substitute',
          title: <FormattedMessage id="Substitute" />,
          type: 'item',
          url: '/substitute',
          icon: SyncOutlined
        },
        {
          id: 'ingore',
          title: <FormattedMessage id="Ingore" />,
          type: 'item',
          url: '/ingore',
          icon: ApiOutlined
        }
      ]
    }
  ]
};

export default systemConfig;
